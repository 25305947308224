import App_Store from "images/Download_on_the_App_Store_Badge_FR_RGB_wht_100217.svg";
import App_Store_Coming_Soon from "images/Download_on_the_App_Store_Badge_FR_RGB_wht_100217_coming_ssom.svg";
import Google_Play from "images/google-play-badge.png";

function DownloadOnTheStore() {
  return (
    <div className="container my-2">
      <div className="pt-2">
        <h5 className="text-center"> Télécharger l'application </h5>
        <h5 className="text-center">
          <u>Le Kiosque Grand Voyageur</u>
          <small>
            <em className="small">&nbsp;by Groupe ProPress</em>
          </small>
        </h5>

        <div className="d-flex justify-content-center align-items-center">
          <a href="https://apps.apple.com/fr/app/id1579387136">
            <img
              src={App_Store}
              alt="Disponible sur l'App Store"
              className="img img-fluid"
              style={{ width: 170 }}
            />
          </a>
          {/* <a href="https://apps.apple.com/fr/app/le-kiosque-grand-voyageur/id1579387136"> */}
            {/* <img
              src={App_Store_Coming_Soon}
              alt="Prochainement dispoanible sur l'App Store"
              className="img img-fluid"
              style={{ width: 170 }}
            /> */}
          {/* </a> */}
          <a
            href="https://play.google.com/store/apps/details?id=com.lekiosquenumerique.grandvoyageur"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={Google_Play}
              alt="Disponible sur Google Play"
              className="img img-fluid"
              style={{ width: 200 }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default DownloadOnTheStore;
