import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Kiosk from "screens/Kiosk";
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import ResettingRequestPassword from "./ResettingRequestPassword";
import ResettingResetPassword from "./ResettingResetPassword";
import Profile from "./Profile";

import { Header, Footer } from "components";

import Axios from "axios";
import Publication from "./Publication";
import PersonalInformation from "./account/PersonalInformation";
import AccountConfirmation from "./AccountConfirmation";

const WrapperRouter = ({
  component: Component,
  showHeader,
  showFooter,
  ...rest
}) => (
  <Route
    render={(props) => (
      <React.Fragment>
        {showHeader && <Header />}
        {(showHeader || showFooter) && (
          <main className="flex-shrink-0">
            <Component {...props} />
          </main>
        )}
        {!showHeader && !showFooter && <Component {...props} />}
        {showFooter && <Footer />}
      </React.Fragment>
    )}
    {...rest}
  />
);

WrapperRouter.defaultProps = {
  showHeader: true,
  showFooter: true,
};

function PrivateRoute({ isAuth, ...rest }) {
  if (isAuth) {
    return <WrapperRouter {...rest} />;
  }

  return (
    <WrapperRouter
      {...rest}
      render={(props) => (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )}
    />
  );
}

const EntryApp = (props) => {
  useEffect(() => {
    if (props.user) {
      Axios.defaults.headers.common["Authorization"] = props.user.apiKey;
    }
  }, [props.user]);
  return (
    <Router>
      <Switch>
        <WrapperRouter exact path="/" component={Kiosk} />
        <WrapperRouter path="/publications" component={Kiosk} />
        <WrapperRouter path="/offer/:offer" component={Kiosk} />
        <WrapperRouter path="/publication/:id" component={Publication} />
        <WrapperRouter
          path="/signin"
          component={SignIn}
          showFooter={false}
          showHeader={false}
        />
        <WrapperRouter
          path="/signup"
          component={SignUp}
          showFooter={false}
          showHeader={false}
        />
        <WrapperRouter
          path="/forgot-password"
          component={ResettingRequestPassword}
          showFooter={false}
          showHeader={false}
        />
        <WrapperRouter
          path="/reset-password/:token"
          component={ResettingResetPassword}
          showFooter={false}
          showHeader={false}
        />
        <WrapperRouter
          path="/email-confirmation/:token"
          component={AccountConfirmation}
          showFooter={false}
          showHeader={false}
        />
        <PrivateRoute
          path="/profile"
          isAuth={props.isAuth}
          component={Profile}
        />
        <PrivateRoute
          path="/account"
          isAuth={props.isAuth}
          component={PersonalInformation}
        />
        <PrivateRoute
          path="/account/personal-information"
          isAuth={props.isAuth}
          component={PersonalInformation}
        />
        <WrapperRouter path="*" component={Kiosk} />
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  apiKey: state.profile?.user?.apiKey,
  kiosk: state.profile?.user?.kiosk,
  user: state.profile?.user,
  isAuth: Boolean(state.profile?.user),
});

export default connect(mapStateToProps)(EntryApp);
