import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";

import axios from "axios";

import configureStore from "store";
import configureApp from "configApp";
import Splash from "screens/Splash";
import { loadCategories } from "store/actions/kiosk";
import EntryApp from "screens/EntryApp";
import { NetworkStatus } from "components";
const App = () => {
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState(null);

  useEffect(() => {
    configureStore().then((store) => {
      configureApp().then(() => {
        //current user
        const token = store.getState().auth.token;

        axios.defaults.headers.common.Authorization =
          process.env.REACT_APP_API_SECRETE;

        //is user logged configure authorization and go to home page
        if (token) {
          axios.defaults.headers.common.Authorization = token;
        }
        // axios.defaults.headers.post.kiosk = 102;
        // axios.defaults.headers.patch.kiosk = "102";
        // axios.defaults.headers.put.kiosk = "102";
        // console.log(axios.defaults);
        store.dispatch(loadCategories());
        setStore(store);
        setLoading(false);
      });
    });
  }, []);

  if (loading) {
    return <Splash />;
  }
  return (
    <Provider store={store}>
      <NetworkStatus />
      <EntryApp />
    </Provider>
  );
};

export default App;
